import { useRef } from 'react'
import Footer from 'src/components/Footer/Footer'
import Header from 'src/components/Header/Header'
import Partners from './Partners/Partners'
import Community from './Community/Community'
import TokenSection from './TokenSection/TokenSection'
import About from './About/About'
import NewHeroBanner from './NewHeroBanner/NewHeroBanner'
import AiFeatures from './AiFeatures/AiFeatures'
import ExploreBots from './ExploreBots/ExploreBots'

import './Landing.sass'

const Landing = () => {
  const scrollable = useRef<HTMLDivElement>(null)

  return (
    <div id="landing" className="page external-page" ref={scrollable}>
      <Header scrollable={scrollable} />
      <NewHeroBanner scrollable={scrollable} />
      <AiFeatures scrollable={scrollable} />
      <ExploreBots scrollable={scrollable} />
      <TokenSection scrollable={scrollable} />
      <Community scrollable={scrollable} />
      <Partners scrollable={scrollable} />
      <About scrollable={scrollable} />
      <Footer />
    </div>
  )
}

export default Landing
