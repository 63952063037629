import React, { RefObject, useRef } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { useScrollOver } from '@gluedigital/scrollastic'

import image from 'src/static/images/landing/token/token-image.png'
import './TokenSection.sass'

const TokenSection = ({
  scrollable
}: {
  scrollable?: RefObject<HTMLElement>
}) => {
  const section = useScrollOver('#landing-token', scrollable)

  const ref = useRef<HTMLElement>(null)

  return (
    <section
      id="landing-token"
      className={'landing-section ' + (section > 0.15 ? 'enter' : '')}
      ref={ref}
    >
      <div className="container">
        <div className="split">
          <div className="item left">
            <h1>
              <MarkdownMessage id="landing.token.title" />
            </h1>
            {/* <p className="mono-space">
              <MarkdownMessage id="landing.token.text" />
            </p> */}
            <Link className="button" to="/token-details">
              <FormattedMessage id="more-info" />
            </Link>
          </div>
          <div className="item right">
            <img id="token-animation" src={image} alt="token animation" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TokenSection
