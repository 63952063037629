import social from './social.png'
import build from './build.png'
import sentiment from './sentiment.png'
import financial from './financial.png'

export const aiImages = {
  social,
  sentiment,
  build,
  financial
}
