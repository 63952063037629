import React, { useState } from 'react'

import { useAppSelector } from 'universal-scripts'
import LanguageSelectorDialog from 'src/components/LanguageSelectorDialog/LanguageSelectorDialog'
// import language from 'src/static/images/network-selector/global.svg'


import './LanguageSelectorHeader.sass'

const LanguageSelector = () => {
  // const dispatch = useDispatch()
  const [chooseLanguage, setChooseLanguage] = useState<boolean>(false)
  const locale = useAppSelector((s) => s.intl.lang)

  return (
    <>
      <section className="language-selector-header">
        <div onClick={() => setChooseLanguage(true)}>
          {/* <img loading="lazy" src={language} alt="globe symbol" /> */}
          <span>{locale?.toUpperCase()}</span>
          <span className="icon icon-angle_down" />
        </div>
      </section>
      <LanguageSelectorDialog
        chooseLanguage={chooseLanguage}
        setChooseLanguage={setChooseLanguage}
      />
    </>
  )
}

export default LanguageSelector
