import { RefObject } from 'react'
import { FormattedMessage } from 'react-intl'
import { useScrollOver } from '@gluedigital/scrollastic'
import MarkdownMessage from '@gluedigital/markdown-message'
import LaunchFast from 'src/components/LaunchFast/LaunchFast'
import MoreAuBNBButton from 'src/components/MoreAuBNBButton/MoreAuBNBButton'
import Parallax from './Parallax/Parallax'
import Sticky from './Sticky/Sticky'
import AiSection from './AiSection/AiSection'

import './NewHeroBanner.sass'

interface NewHeroBannerProps {
  scrollable: RefObject<HTMLDivElement>
}

const NewHeroBanner = ({ scrollable }: NewHeroBannerProps) => {
  const section = useScrollOver('#ai-section', scrollable)

  return (
    <section id="landing-hero-banner" className="landing-section">
      <div id="background-banner">
        <div className="container">
          <div className="content content-left">
            <div className="wrapper">
              <h1>
                <FormattedMessage id="landing.new-hero-banner.title" />
              </h1>
              <p>
                <MarkdownMessage id="landing.new-hero-banner.text" />
              </p>
              <div className="buttons">
                <LaunchFast />
              </div>
            </div>
          </div>
          <div className="content" />
        </div>
        <Parallax scrollable={scrollable} />
      </div>
      <AiSection section={section} />
      <Sticky section={section} />
    </section>
  )
}

export default NewHeroBanner
