import { Route, Routes } from 'react-router'
import Landing from './Landing/Landing'
import NotFound from './NotFound/NotFound'
import { useSelector } from 'react-redux'
import Meta from 'src/components/Meta/Meta'
import TokenDetails from './TokenDetails/TokenDetails'

const App = () => {
  const currentLang = useSelector((s: any) => s.intl.locale)

  return (
    <>
      <Meta />
      <main id="app" className={`language-${currentLang}`}>
        <div className="pages-transition-group">
          <Routes>
            <Route path="/" index element={<Landing />} />
            <Route path="/token-details" element={<TokenDetails />} />
            <Route element={<NotFound />} />
          </Routes>
        </div>
      </main>
    </>
  )
}

export default App
