import { FormattedMessage } from 'react-intl'
import { useScrollOver } from '@gluedigital/scrollastic'
import Clouds from 'src/components/Clouds/Clouds'
import MarkdownMessage from '@gluedigital/markdown-message'

import autoWebp from 'src/static/images/landing/bots/auto-bot.webp'
import autoPng from 'src/static/images/landing/bots/auto-bot.png'
import ressWebp from 'src/static/images/landing/bots/ress-bot.webp'
import ressPng from 'src/static/images/landing/bots/ress-bot.png'
import './ExploreBots.sass'

const ExploreBots = ({ scrollable }: { scrollable?: any }) => {
  const sectionAuto = useScrollOver('#bot-auto', scrollable)
  const sectionRess = useScrollOver('#bot-ress', scrollable)
  return (
    <section id="landing-explore-bots" className="landing-section">
      <Clouds cloudType={6} cloudPosition="top" color="white" />
      <section
        id="bot-auto"
        className={'bot-section ' + (sectionAuto > 0.3 ? 'enter' : '')}
      >
        <div className="container">
          <div className="content content-left">
            <div className="wrapper">
              <h1>
                <FormattedMessage id="landing.explore-bots.bot-auto.title" />
              </h1>
              <p className="mono-space">
                <MarkdownMessage id="landing.explore-bots.bot-auto.text" />
              </p>
              <em>
                <FormattedMessage id="coming-soon" />
              </em>
              {/* <div className="buttons buttons-auto">
                <a href="" className="button button-visit">
                  <FormattedMessage
                    id="landing.explore-bots.visit"
                    values={{ botName: 'Auto' }}
                  />
                </a>
                <a href="" className="button button-buy">
                  <FormattedMessage
                    id="landing.explore-bots.buy"
                    values={{ token: '$AUTO' }}
                  />
                </a>
              </div> */}
            </div>
          </div>
          <div className="content content-right">
            <picture>
              <source srcSet={autoWebp} type="image/webp" />
              <img src={autoPng} alt="bot auto" />
            </picture>
          </div>
        </div>
      </section>
      <section
        id="bot-ress"
        className={'bot-section ' + (sectionRess > 0.3 ? 'enter' : '')}
      >
        <div className="container">
          <div className="content content-left">
            <picture>
              <source srcSet={ressWebp} type="image/webp" />
              <img src={ressPng} alt="bot ress" />
            </picture>
          </div>
          <div className="content content-right">
            <div className="wrapper">
              <h1>
                <FormattedMessage id="landing.explore-bots.bot-ress.title" />
              </h1>
              <p className="mono-space">
                <MarkdownMessage id="landing.explore-bots.bot-ress.text" />
              </p>
              <em>
                <FormattedMessage id="coming-soon" />
              </em>
              {/* <div className="buttons buttons-ress">
                <a href="" className="button button-visit">
                  <FormattedMessage
                    id="landing.explore-bots.visit"
                    values={{ botName: 'Ress' }}
                  />
                </a>
                <a href="" className="button button-buy">
                  <FormattedMessage
                    id="landing.explore-bots.buy"
                    values={{ token: '$RESS' }}
                  />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Clouds cloudType={3} color="#F3F3F3" />
    </section>
  )
}

export default ExploreBots
