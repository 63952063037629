import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { Link } from 'react-router-dom'
import About from '../Landing/About/About'
import Footer from 'src/components/Footer/Footer'
import TokenInfoBanner from './TokenInfoBanner/TokenInfoBanner'

import logo from 'src/static/logos/autolayer/autolayerLogoWhite.svg'
import './TokenDetails.sass'

export enum TokenInfoItem {
  LIQUIDITY = 'liquidity',
  COMMUNITY = 'community',
  ADVANCED = 'advanced'
}

const TokenDetails = () => {
  return (
    <div id="token-details" className="page">
      <header>
        <div className="container">
          <Link to="/">
            <img className="logo" src={logo} alt="autolayer logo" />
          </Link>
          <a href="/token-details#token-info-banner" className="button">
            <MarkdownMessage id="token-info.buy" />
          </a>
        </div>
      </header>
      <div className="container">
        <div className="breadcrumb">
          <Link to="/" className="breadcrumb-link">
            <span className="icon icon-angle_left" />
            <span>
              <FormattedMessage id="back-home" />
            </span>
          </Link>
        </div>
        <h1>
          <FormattedMessage id="token-details.title" />
        </h1>
        <ul className="token-info-list">
          {[...Object.values(TokenInfoItem)].map((s) => (
            <li key={s}>
              <article className={`token-info-item item-${s}`} >
                <section className="item left-item">
                  <h2>
                    <span>
                      <FormattedMessage id={`token-info-item.${s}.title`} />
                    </span>
                  </h2>
                  <p>
                    <span>
                      <FormattedMessage id={`token-info-item.${s}.short-text`} />
                    </span>
                  </p>
                </section>
                <section className="item right-item">
                  <img
                    src={`/images/landing/token-details/${s}.png`}
                    alt={`${s} illustration`}
                  />
                </section>
              </article>
            </li>
          ))}
        </ul>
      </div>
      <TokenInfoBanner />
      <About />
      <Footer />
    </div>
  )
}

export default TokenDetails
