import bot from 'src/static/images/landing/new-hero-banner/bot.webp'
import botDefault from 'src/static/images/landing/new-hero-banner/bot.png'

import './Sticky.sass'

interface StickyProps {
  section: number
}

const Sticky = ({ section }: StickyProps) => {

  return (
    <div
      id="section-sticky"
      className={section >= 0 && section < 0.9 ? 'enter' : ''}
    >
      <div className="container">
        <div className="content content-left" />
        <div className="content content-right">
          <picture id="sphere" className={section >= 0.2 ? 'glow' : ''}>
            <source srcSet={bot} type="image/webp" />
            <img src={botDefault} alt="Autolayer ai bot" />
          </picture>
        </div>
      </div>
    </div>
  )
}

export default Sticky
