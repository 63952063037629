import MarkdownMessage from '@gluedigital/markdown-message'
import { FormattedMessage } from 'react-intl'
import { exchangeHref } from 'src/utils/utils'

import './TokenInfoBanner.sass'

export enum Exchange {
  KUCOIN = 'kucoin',
  GATEIO = 'gate-io',
  MEXC = 'mexc',
  PANCAKESWAP = 'pancakeswap'
}

const TokenInfoBanner = () => {


  return (
    <section id="token-info-banner">
      <div className="container">
        <h3>
          <MarkdownMessage id="token-info.buy" />
        </h3>
        <div id="info-banner-grid">
          {Object.values(Exchange).map((exchange) => (
              <a
                href={exchangeHref(exchange)}
                className="button"
                target="_blank"
                rel="noopener noreferrer"
                key={exchange}
              >
              <img
                src={`images/landing/token-details/exchanges/${exchange}.png`}
                alt={`${exchange} logo`}
              />
              <span>
                <FormattedMessage id={`exchange.${exchange}`} />
              </span>
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}

export default TokenInfoBanner
