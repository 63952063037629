import './Clouds.sass'

const Clouds = ({
  color = '#ffffff',
  cloudType = 1,
  cloudPosition = 'bottom'
}: {
  color?: string
  cloudType?: number
  cloudPosition?: string
}) => {
  return (
    <div
      style={{ backgroundColor: color }}
      className={`clouds-wrapper cloud-type-${cloudType} cloud-position-${cloudPosition}`}
    />
  )
}

export default Clouds
