import { Exchange } from "src/routes/TokenDetails/TokenInfoBanner/TokenInfoBanner"

export function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: ReturnType<typeof setTimeout>
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export function addLeadingZero(number: number): string {
  return number < 10 ? '0' + number : number.toString()
}

export const exchangeHref = (exchange: Exchange): string => {
  switch (exchange) {
    case Exchange.KUCOIN:
      return 'https://trade.kucoin.com/LAY3R-USDT'
    case Exchange.GATEIO:
      return 'https://www.gate.io/trade/LAY3R_USDT'
    case Exchange.MEXC:
      return 'https://www.mexc.com/exchange/LAY3R_USDT'
    case Exchange.PANCAKESWAP:
      return 'https://pancakeswap.finance/?outputCurrency=0xF06ce11836851d71E74e4ffeFa7b73FCc8A27786'
    default:
      return ''
  }
}
