import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import Clouds from 'src/components/Clouds/Clouds'

import './AiSection.sass'
import LaunchFast from 'src/components/LaunchFast/LaunchFast'

interface AiSectionProps {
  section: number
}

const AiSection = ({ section }: AiSectionProps) => {
  return (
    <div id="ai-section">
      <div className="rocks-column" />
      <div className="container">
        <div className="content content-left">
          <div
            className={
              'wrapper ' + (section > 0.2 && section < 0.9 ? 'enter' : '')
            }
          >
            <h1>
              <span>
                <MarkdownMessage id="landing.hero-banner.ai-title" />
              </span>
            </h1>
            <p className="mono-space">
              <FormattedMessage id="landing.hero-banner.ai-text" />
            </p>
            <LaunchFast text="discover-ai" />
          </div>
        </div>
        <div className="content content-right">
          {[1, 2, 3].map((r: number, i: number) =>
            <div key={`rocks-${r}`} className={`rocks rocks-${r}`} style={{
              animationDelay: `${i / 10}s`,
              backgroundImage: `url('/images/landing/new-hero-banner/rocks/rocks-${r}.png')`
            }} />
          )}
        </div>
      </div>
      <Clouds cloudType={5} />
    </div>
  )
}

export default AiSection
