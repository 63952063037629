import { RefObject } from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { useScrollOver } from '@gluedigital/scrollastic'
import { aiImages } from 'src/static/images/landing/ai'

import './AiFeatures.sass'

export enum AiFeaturesEnum {
  BUILD = 'build',
  SENTIMENT = 'sentiment',
  FINANCIAL = 'financial',
  SOCIAL = 'social'
}

const AiFeatures = ({
  scrollable
}: {
  scrollable?: RefObject<HTMLElement>
}) => {
  const section = useScrollOver('#landing-ai', scrollable)

  return (
    <section
      id="landing-ai"
      className={'landing-section ' + (section > 0.15 ? 'enter' : '')}
    >
      <div className="container">
        <h1>
          <FormattedMessage id="landing-ai.title" />
        </h1>
        <div className={'grid-ai ' + (section > 0.25 ? 'enter' : '')}>
          {Object.values(AiFeaturesEnum).map((feature, i) => (
            <article
              key={feature + i}
              style={{ transitionDelay: i * 0.25 + 's' }}
            >
              <section className="info">
                <h2>
                  <MarkdownMessage id={`landing-ai.${feature}-title`} />
                </h2>
                <p>
                  <FormattedMessage id={`landing-ai.${feature}-text`} />
                </p>
              </section>
              <img src={aiImages[feature]} alt={`${feature} illustration`} />
            </article>
          ))}
        </div>
        {/* <a href="" className="button">More</a> */}
      </div>
    </section>
  )
}

export default AiFeatures
